<template>
  <fw-layout wide back-to="/manage">
    <template #main-content>
      <PanelNotifications />
    </template>
  </fw-layout>
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  components: {
    PanelNotifications,
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
  },
}
</script>
